const toNormal = (num) => {
    const n = Math.round(num * 100) / 100;
    const s1 = n.toString().split('.');
    let nStr = n.toString();
    if (s1[0].length > 4) {
        nStr = '';
        const s2 = s1[0].split('');
        let l = s2.length;
        let counter = 0;
        while (l > 0) {
            l--;
            if (counter % 3 === 0 && counter !== 0) {
                nStr = s2[l] + ' ' + nStr;
            }
            else {
                nStr = s2[l] + nStr;
            }
            counter++;
        }
        if (s1[1]) {
            nStr = nStr + '.' + s1[1];
            if (s1[1].length === 1)
                nStr = nStr + '0';
        }
    }
    return nStr;
};
const toNormal2 = (num) => {
    return Math.round(num * 1000) / 1000;
};
export { toNormal, toNormal2 };
