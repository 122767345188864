const addResults = (resultsState, calcResultsFieldSelector) => {
    const calcResultsField = document.querySelector(`${calcResultsFieldSelector} textarea`);
    let dataString = '';
    let resultString = '';
    for (const key in resultsState.data) {
        if (Object.prototype.hasOwnProperty.call(resultsState.data, key)) {
            const element = resultsState.data[key];
            dataString += `<tr><td>${element[0]}</td><td>${element[1]}</td></tr>`;
        }
    }
    for (const key in resultsState.result) {
        if (Object.prototype.hasOwnProperty.call(resultsState.result, key)) {
            const element = resultsState.result[key];
            resultString += `<tr><td>${element[0]}</td><td>${element[1]}</td><td>${element[2]}</td></tr>`;
        }
    }
    const table = `
        <table>
            <thead><tr><td><strong>Исходные данные</strong></td></tr></thead>
            <tbody>
                ${dataString}
            </tbody>
        </table>
        <table style="margin-top:20px">
            <thead><tr><td><strong>Результаты</strong></td></tr></thead>
            <tbody>
                ${resultString}
            </tbody>
        </table>
    `;
    calcResultsField.innerText = table;
};
export default addResults;
