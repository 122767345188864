export default function extendCalcTeaser(selector) {
    const extendCalcTeaser = document.querySelector(selector);
    if (!extendCalcTeaser)
        return false;
    const calculator = document.querySelector('.calculator');
    if (!calculator)
        return false;
    extendCalcTeaser.addEventListener('focusout', (e) => {
        const input = e.target;
        const sameInput = calculator.querySelector(`[name = "${input.name}"]`);
        sameInput.value = input.value;
    });
}
;
