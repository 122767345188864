import { toNormal } from './toNormal';
const results = (state) => {
    const newState = {
        data: {
            f1: ['Разница в весе ТС, кг', state.data.f1],
            f2: ['Масса груза за рейс, тн', state.data.f2],
            f3: ['Дистанция перевозки, км', state.data.f3],
            f4: ['Количество рейсов в день', state.data.f4],
            f5: ['Количество рабочих дней в год', state.data.f5],
            f6: ['з/п водителей в месяц р.', state.data.f6],
            f7: ['Стоимость топлива руб. за литр', state.data.f7],
            f8: ['Средний расход топлива на 100 км пробега, л.', state.data.f8],
            f9: ['Количество единиц техники', state.data.f9],
            f10: ['Количество лет владения', state.data.f10],
        },
        result: {
            r1: ['Объем перевезенного груза', toNormal(state.result.r1), 'тн. за год'],
            r2: ['Объем дополнительно перевезенного груза', toNormal(state.result.r2), 'тн. за год'],
            r3: ['Стоимость ТО, ремонта и замены шин', toNormal(state.result.r3), 'руб.'],
            r4: ['Страховка ТС КАСКО и ОСАГО', toNormal(state.result.r4), 'руб.'],
            r5: ['Затраты на топливо', toNormal(state.result.r5), 'руб. в год'],
            r7: ['Экономия на топливе в год на порожнем рейсе', toNormal(state.result.r7), 'руб. в год'],
            r8: ['Затраты руб. на 1 км пробега', toNormal(state.result.r8), 'руб. за км.'],
            r9: ['Стоимость перевезенной 1 тн груза', toNormal(state.result.r9), 'руб. за тонну'],
            r10: ['Пробег комбинации', toNormal(state.result.r10), 'км. в год'],
            r11: ['Ваши затраты на комбинаци', toNormal(state.result.r11), 'руб. в год'],
            r12: ['Размер дополнительной экономии в год', toNormal(state.result.r12), 'руб. за год'],
            r13: ['Размер дополнительной экономии ВСЕГО', toNormal(state.result.r13), 'руб.'],
            r14: ['Кроме этого, вы сможете перевезти дополнительно', toNormal(state.result.r14), 'тонн груза']
        }
    };
    return newState;
};
export default results;
