import { $ } from './utils';
const partnersPopup = (selComponent, selTrigger, selTarget) => {
    const partners = $(selComponent);
    if (!partners)
        return false;
    console.log(selTrigger);
    const showPartnerInfo = ({ target }) => {
        if (target.dataset.comnponent === selTrigger[0])
            return false;
        let trigger = target;
        if (target.tagName === selTrigger[1])
            trigger = target.parentNode;
        const currentPopup = trigger.querySelector(selTarget);
        if (currentPopup) {
            const popupList = partners.querySelectorAll(selTarget);
            popupList.forEach((item) => popupHide(item));
            popupShow(currentPopup);
            const top = trigger.offsetTop + trigger.offsetHeight - 20;
            currentPopup.style.top = top.toString();
            const left = (triggerLeft, wrapperWidth, popupWidth) => {
                let leftOffset;
                const space = wrapperWidth - popupWidth;
                if (triggerLeft <= space) {
                    leftOffset = triggerLeft;
                }
                else
                    leftOffset = space;
                return leftOffset;
            };
            const leftOffset = left(trigger.offsetLeft, partners.offsetWidth, currentPopup.offsetWidth);
            currentPopup.style.left = leftOffset.toString();
            const arrow = trigger.querySelector('[data-target="arrow"]');
            arrow.style.left = (trigger.offsetLeft + (trigger.offsetWidth / 2) - leftOffset).toString();
            const close = trigger.querySelector('[data-target="close"]');
            const hideOnClose = () => {
                popupHide(currentPopup);
                close.off('click', hideOnClose);
            };
            close && close.on('click', hideOnClose);
        }
    };
    const popupShow = (popup) => {
        popup.classList.remove('popup_hide');
        popup.classList.add('popup_show');
    };
    const popupHide = (popup) => {
        popup.classList.remove('popup_show');
        popup.classList.add('popup_hide');
    };
    partners.on('click', showPartnerInfo);
};
export default partnersPopup;
