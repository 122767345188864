import { inputValidate } from './';
import submiter from '../submiter';
class FormValidate {
    constructor(props) {
        this.checkInputs = (inputs) => {
            inputs.forEach((input) => {
                const wrapper = input.parentNode;
                const errorTextBox = wrapper.querySelector(this.inputErrorTextSelector);
                inputValidate(input, wrapper, errorTextBox);
            });
        };
        this.checkInput = (event) => {
            const target = event.target;
            if (!target.classList.contains('required'))
                return true;
            const wrapper = target.parentNode;
            const errorTextBox = wrapper.querySelector(this.inputErrorTextSelector);
            inputValidate(target, wrapper, errorTextBox);
            this.updateFormStatus();
        };
        this.updateFormStatus = () => {
            if (this.form.querySelector('.error') || this.form.dataset.isrobot === 'true') {
                this.state.formStatus = 'invalid';
                this.formErrorText && this.formErrorText.classList.remove('hide');
            }
            else {
                this.state.formStatus = 'valid';
                this.formErrorText && this.formErrorText.classList.add('hide');
            }
        };
        this.checkForm = () => {
            const inputs = this.form.querySelectorAll('.required');
            this.checkInputs(inputs);
            this.updateFormStatus();
        };
        this.submitHandler = (event) => {
            event.preventDefault();
            this.checkForm();
            if (this.state.formStatus === 'valid') {
                submiter(this.form);
                // this.form.submit();
            }
        };
        this.init = () => {
            this.form.addEventListener('change', this.checkInput, false);
            this.form.addEventListener('submit', this.submitHandler, false);
        };
        this.form = props.form;
        this.inputErrorTextSelector = props.inputErrorTextSelector;
        this.formErrorText = this.form.querySelector(props.formErrorTextSelector);
        this.submitBtn = this.form.querySelector('.submit');
        this.state = {
            formStatus: ''
        };
    }
}
export default FormValidate;
