import { $, $$ } from './utils';
const trunk = () => {
    const items = $$('.trunk-slide');
    const content = $('.trunk-content');
    const toggleBtn = $('#navToggle');
    const toggleFiltrtBtn = $('#mFilterToggle');
    const open = (settings) => {
        items.forEach((item) => {
            if (item.classList.contains(settings.exclude)) {
                return false;
            }
            item.classList.add('open');
            item.classList.remove('close');
        });
        toggleBtn.classList.add('active');
    };
    const close = () => {
        items.forEach((item) => {
            item.classList.add('close');
            item.classList.remove('open');
        });
        toggleBtn.classList.remove('active');
    };
    const navToggle = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (content.classList.contains('open')) {
            close();
        }
        else {
            open({ exclude: 'trunk-filter' });
        }
    };
    toggleBtn && toggleBtn.on('click', navToggle);
    const filterToggle = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (content.classList.contains('open')) {
            close();
        }
        else {
            open({ exclude: 'trunk-nav' });
        }
    };
    toggleFiltrtBtn && toggleFiltrtBtn.on('click', filterToggle);
    const contentClose = () => {
        if (content.classList.contains('open')) {
            close();
        }
    };
    content && content.on('click', contentClose);
};
export default trunk;
